import { Component, OnInit, NgZone, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { CovidDataService } from '../covid-data.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  private chart: am4charts.PieChart;
  @Input() data: any;
  @Input() valueField: string;
  @Input() chartId: string;
  @Input() title: string;

  constructor(
    private ngZone: NgZone,
    private covidDataService: CovidDataService
  ) { }

  ngOnInit(): void {
    this.covidDataService.paramsChanged
      .subscribe(params => {
        this.drawChart();
      });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.drawChart();
    });
  }

  drawChart() {
    if (this.chart) {
      this.chart.dispose();
    }

    let chart = am4core.create(this.chartId, am4charts.PieChart);
    chart.data = this.data;

    const title = chart.titles.create();
    title.text = this.title;
    title.fontSize = 18;
    title.fill = am4core.color('#999');
    title.marginBottom = 30;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = this.valueField;
    pieSeries.dataFields.category = 'continent';
    pieSeries.ticks.template.stroke = am4core.color('#FFF');
    pieSeries.labels.template.disabled = true;
    pieSeries.labels.template.fill = am4core.color('#999');
    pieSeries.labels.template.radius = am4core.percent(-20);

    this.chart = chart;
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
