import { Component } from '@angular/core';
import { CovidDataService } from './covid-data.service';
import { Params } from './params';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CovidData } from './_types/covid-data';
import { locations } from './data/locations';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  dataError = false;
  data: any;
  tableData: any;
  regionData: any;
  loading = true;
  tableLoading = true;
  regionsLoading = true;
  params = new Params();
  order = 'new_cases';
  locationsMap: any;
  currentLocation: string;
  localLocation: string;

  constructor(
    private covidDataService: CovidDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .subscribe(e => {
        if (e.constructor.name === 'NavigationEnd' && this.router.navigated) {
          this.route.queryParams
            .subscribe(params => {
              Object.assign(this.params, params);
              if (this.params.location) {
                this.currentLocation = locations.filter(loc => loc.country_code === params.location)[0].location;
                this.fetchData();
              } else {
                navigator.geolocation.getCurrentPosition((position: any) => {
                  this.covidDataService.getGeolocation(position.coords)
                    .subscribe(result => {
                      const address = result.results[0].address_components;
                      const country = address.filter(addr => addr.types.includes('country'))[0].long_name;
                      this.currentLocation = country;
                      this.params.location = locations.filter(loc => loc.location === country)[0].country_code;
                      this.covidDataService.setParams(this.params);
                      this.fetchData();
                    });
                });
              }

            });
        }
      });

    this.covidDataService.paramsChanged
      .subscribe(params => {
        this.params = params;
      });
  }

  fetchData() {
    this.regionsLoading = true;
    this.loading = true;
    this.tableLoading = true;

    this.covidDataService.getSummary(this.params)
      .subscribe((data: CovidData) => {
        this.data = data; 
        this.loading = false;
      }, err => this.dataError = true);

    //if (!this.params.location || this.params.location === 'World') {
    //  this.covidDataService.getTableData(this.params)
    //    .subscribe((data) => {
    //      this.tableData = data; 
    //      this.tableLoading = false;
    //    }, err => this.dataError = true);
    //} else {
    //  this.covidDataService.getLocationData(this.params)
    //    .subscribe((data) => {
    //      this.tableData = data; 
    //      this.tableLoading = false;
    //    }, err => this.dataError = true);
    //}

    //this.covidDataService.getDataByContinent(this.params)
    //  .subscribe((data) => {
    //    this.regionData = data; 
    //    this.regionsLoading = false;
    //  }, err => this.dataError = true);

  }

  setLocations(locationsMap: any) {
    this.locationsMap = locationsMap;
  }
}
