<nav id="main-nav" fxLayout="row" fxLayoutGap="10px" fxFlex>
  <h1 ngClass.lt-sm="mobile"><a id="masthead">Covid19 Spy</a></h1>
  <span fxFlex></span>
  <div fxShow.lt-lg fxHide>
    <button id="filter"
            (click)="openFiltersModal()"
            mat-icon-button>
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div fxHide.lt-lg>
    <app-filter (locationsLoaded)="setLocations($event)"></app-filter>
  </div>
</nav>
