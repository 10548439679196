import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from './params';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from './_types/location';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CovidDataService {
  @Output() paramsChanged = new EventEmitter<Params>();
  serverUrl = environment.serverUrl;
  locationsMap: any = {};

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  getSummary(params = {}) {
    return this.http.get(`${this.serverUrl}/summary`, { params });
  }

  getTableData(params = {}) {
    return this.http.get(`${this.serverUrl}/table-data`, { params });
  }

  getLocationData(params = {}) {
    return this.http.get(`${this.serverUrl}/location-data`, { params });
  }

  getDataByContinent(params = {}) {
    return this.http.get(`${this.serverUrl}/continents`, { params });
  }

  getTimeSeries(params = {}) {
    return this.http.get(`${this.serverUrl}/time-series`, { params });
  }

  getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(`${this.serverUrl}/locations`);
  }

  setParams(params = new Params()) {
    console.log('params changed in service');
    for (let key in params) {
      if (!params[key]) delete params[key];
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
    });

    this.paramsChanged.emit(params);
  }

  getGeolocation(position: any): Observable<any> {
    const params = {
      latlng: `${position.latitude},${position.longitude}`,
      key: 'AIzaSyBxj9PFSsb5n0AVjFglaK_axGLkC4lknkc'
    }
    return this.http.get<any>('https://maps.googleapis.com/maps/api/geocode/json', { params });
  }
}
