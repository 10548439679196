import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'largeNumber'
})
export class LargeNumberPipe implements PipeTransform {

  transform(value: string | number): string | number {
    const num = typeof value === 'string' ? parseInt(value.replace(/,/g, '')) :value;
    let output = '';
    switch (true) {
      case num > 999999:
        output = (num / 1000000).toFixed(2) + 'm';
        break;

      default:
        output = String(value);
    }
    return output;
  }

}
