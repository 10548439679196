import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CovidData } from '../_types/covid-data';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  fields = [
    { name: 'location', label: 'Location' },
    { name: 'new_cases', label: 'New Cases' },
    { name: 'total_cases', label: 'Total Cases' },
    { name: 'new_deaths', label: 'New Deaths' },
    { name: 'total_deaths', label: 'Total Deaths' },
  ];

  @Input() data: CovidData[];
  @Input() order: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: MatTableDataSource<CovidData>;
  displayedColumns = this.fields.map(field => field.name);

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<CovidData>(this.data);
    this.sort.active = 'new_cases';
    this.sort.direction = 'desc';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

}
