import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(collection: any[], field: string, reverse = false): any[] {
    const out = collection ? collection.slice() : null;
    if (out) {
      let isString = false;
      out
        .map(item => item[field])
        .forEach(value => {
          isString = isNaN(value);
          if (isString) {
            return;
          }
        });
      out.sort((a, b) => (isString ? a[field].localeCompare(b[field]) : a[field] - b[field]));
      if (reverse) {
        out.reverse();
      }
    }
    return out;
  }
}
