<div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap.gt-mg="10px">
  <mat-form-field>
    <mat-label>Location</mat-label>
    <mat-select name="location" [(ngModel)]="params.location">
      <mat-option *ngFor="let location of locations" [value]="location.country_code">{{ location.location }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Start date</mat-label>
    <input tabindex="-1" matInput [(ngModel)]="params.startDate" [matDatepicker]="startDate">
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field>
    <mat-label>End date</mat-label>
    <input tabindex="-1" matInput [(ngModel)]="params.endDate" [matDatepicker]="endDate">
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate></mat-datepicker>
  </mat-form-field>
  
  <div mat-dialog-actions fxLayoutAlign.lt-sm="end center">
      <button id="update-charts-button" 
              [disabled]="!params.location && !params.startDate && !params.endDate" 
              mat-raised-button 
              matTooltip="Update charts" 
              ngClass.gt-sm="mat-icon-button" 
              (click)="updateParameters()">
        <mat-icon fxHide.lt-lg >refresh</mat-icon>
        <span fxHide fxShow.lt-lg>Update</span>
      </button>


  </div>
</div>
