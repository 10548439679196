<div id="highlights" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
  <div fxLayoutGap="20px" fxFlex fxFlex.lt-lg="50%" fxLayout.gt-md="row" fxLayout.lt-lg="column">
    <div class="highlight-tile" fxFlex>
      <h3>New Cases</h3>
      <div>
        <div class="large-number">
          {{ (data.data.new_cases | number | largeNumber ) || 'N/A' }}
        </div>
        <div class="change main" 
             [ngClass]="{ 'positive': data.average7Days.new_cases_change < 0, 'negative': data.average7Days.new_cases_change > 0 }"
             *ngIf="data.average7Days.new_cases_change != 0">
          <mat-icon>{{ data.average7Days.new_cases_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.new_cases_change }}%
        </div>
      </div>
      <div class="average">
          7-day Avg: {{ (data.average7Days.new_cases | number | largeNumber) || 'N/A' }}
        <span class="change" 
             [ngClass]="{ 'positive': data.average7Days.new_cases_average_change < 0, 'negative': data.average7Days.new_cases_average_change > 0 }"
             *ngIf="data.average7Days.new_cases_average_change != 0">
          <mat-icon>{{ data.average7Days.new_cases_average_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.new_cases_average_change }}%
      </span>
      </div>
    </div>

    <div class="highlight-tile" fxFlex fxFlex.lt-lg="50">
      <h3>New Deaths</h3>
      <div>
        <div class="large-number">
          {{ (data.data.new_deaths | number | largeNumber ) || 'N/A' }}
        </div>
        <div class="change main" 
             [ngClass]="{ 'positive': data.average7Days.new_deaths_change < 0, 'negative': data.average7Days.new_deaths_change > 0 }"
             *ngIf="data.average7Days.new_deaths_change != 0">
          <mat-icon>{{ data.average7Days.new_deaths_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.new_deaths_change }}%
        </div>
      </div>
      <div class="average">
          7-day Avg: {{ (data.average7Days.new_deaths | number | largeNumber) || 'N/A' }}
        <span class="change" 
             [ngClass]="{ 'positive': data.average7Days.new_deaths_average_change < 0, 'negative': data.average7Days.new_deaths_average_change > 0 }"
             *ngIf="data.average7Days.new_deaths_average_change != 0">
          <mat-icon>{{ data.average7Days.new_deaths_average_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.new_deaths_average_change }}%
      </span>
      </div>
    </div>
  </div>

  <div fxFlex fxLayoutGap="20px" fxFlex.lt-lg="50%" fxLayout.gt-md="row" fxLayout.lt-lg="column">
    <div class="highlight-tile" fxFlex fxFlex.lt-lg="50%">
      <h3>Total Cases</h3>
      <div>
        <div class="large-number">
          {{ (data.data.total_cases | number | largeNumber ) || 'N/A' }}
        </div>
        <div class="change main"
             [ngClass]="{ 'positive': data.average7Days.total_cases_change < 0, 'negative': data.average7Days.total_cases_change > 0 }"
             *ngIf="data.average7Days.total_cases_change != 0">
          <mat-icon>{{ data.average7Days.total_cases_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.total_cases_change }}%
        </div>
      </div>
      <div class="average">
        &nbsp;
      </div>
    </div>

    <div class="highlight-tile" fxFlex fxFlex.lt-lg="50%">
      <h3>Total Deaths</h3>
      <div>
        <div class="large-number">
          {{ (data.data.total_deaths | number | largeNumber ) || 'N/A' }}
        </div>
        <div class="change main" 
             [ngClass]="{ 'positive': data.average7Days.total_deaths_change < 0, 'negative': data.average7Days.total_deaths_change > 0 }"
             *ngIf="data.average7Days.total_deaths_change != 0">
          <mat-icon>{{ data.average7Days.total_deaths_change > 0 ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          {{ data.average7Days.total_deaths_change }}%
        </div>
      </div>
      <div class="average">
        &nbsp;
      </div>
    </div>
  </div>
</div>
