<div class="mat-elevation-z8">
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container *ngFor="let field of fields" [matColumnDef]="field.name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header start="desc">{{ field.label }}</th>
    <td mat-cell *matCellDef="let element">{{ element[field.name] }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row, columns: displayedColumns"></tr>
</table>

<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButton></mat-paginator>
</div>
