import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() locationsLoaded = new EventEmitter<any>();

  constructor( private dialog: MatDialog ) { }

  ngOnInit(): void {
  }

  openFiltersModal() {
    const dialogRef = this.dialog.open(FilterModalComponent, {
      width: '400px'
    });
  }

  setLocations(locationsMap: any) {
    this.locationsLoaded.emit(locationsMap);
  }
}
