import { Component, OnInit, Input, NgZone } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import { CovidDataService } from '../covid-data.service';
import { Params } from '../params';
import { ActivatedRoute } from '@angular/router';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

@Component({
  selector: 'app-time-series',
  templateUrl: './time-series.component.html',
  styleUrls: ['./time-series.component.scss']
})
export class TimeSeriesComponent implements OnInit {
  data: any;
  timeSeriesLoading = true;
  timeSeriesError = false;
  chart: am4charts.XYChart;
  chart2: am4charts.XYChart;
  chart3: am4charts.XYChart;
  chart4: am4charts.XYChart;
  params = new Params();
  dcMaximized = false;
  ddMaximized = false;
  tcMaximized = false;
  tdMaximized = false;

  constructor( 
    private ngZone: NgZone,
    private covidDataService: CovidDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      Object.assign(this.params, params);
      this.fetchData();
    });

    this.covidDataService.paramsChanged
      .subscribe(params => {
        this.params = params;
        this.fetchData();
      });
  }

  fetchData() {
    if (this.chart) {
      this.chart.dispose();
    }

    if (this.chart2) {
      this.chart2.dispose();
    }

    if (this.chart3) {
      this.chart3.dispose();
    }

    if (this.chart4) {
      this.chart4.dispose();
    }

    this.timeSeriesLoading = true;

    this.covidDataService.getTimeSeries(this.params)
      .subscribe((data) => {
        this.data = data;
        this.timeSeriesLoading = false;
        this.createChart();
      }, (err) => {
        this.timeSeriesError = true;
        this.timeSeriesLoading = false;
      });
  }

  createChart() {
    let chart = am4core.create('daily-cases-timeseries', am4charts.XYChart);
    let chart2 = am4core.create('daily-deaths-timeseries', am4charts.XYChart);
    let chart3 = am4core.create('total-cases-timeseries', am4charts.XYChart);
    let chart4 = am4core.create('total-deaths-timeseries', am4charts.XYChart);

    chart.colors.list = [am4core.color('#006699')];
    chart2.colors.list = [am4core.color('#990000')];
    chart3.colors.list = [am4core.color('#006699')];
    chart4.colors.list = [am4core.color('#990000')];


    chart.data = this.data;
    chart2.data = this.data;
    chart3.data = this.data;
    chart4.data = this.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    this.styleAxis(dateAxis);
    this.styleAxis(valueAxis);

    let dateAxis2 = chart2.xAxes.push(new am4charts.DateAxis());
    let valueAxis2 = chart2.yAxes.push(new am4charts.ValueAxis());
    this.styleAxis(dateAxis2);
    this.styleAxis(valueAxis2);

    let dateAxis3 = chart3.xAxes.push(new am4charts.DateAxis());
    dateAxis3.renderer.labels.template.fontSize = 10;
    let valueAxis3 = chart3.yAxes.push(new am4charts.ValueAxis());
    this.styleAxis(dateAxis3);
    this.styleAxis(valueAxis3);

    let dateAxis4 = chart4.xAxes.push(new am4charts.DateAxis());
    dateAxis4.renderer.labels.template.fontSize = 10;
    let valueAxis4 = chart4.yAxes.push(new am4charts.ValueAxis());
    this.styleAxis(dateAxis4);
    this.styleAxis(valueAxis4);

    let dailyCaseSeries = chart.series.push(new am4charts.ColumnSeries());
    dailyCaseSeries.dataFields.valueY = 'cases';
    dailyCaseSeries.dataFields.dateX = 'date';
    dailyCaseSeries.name = 'Daily cases';

    let dailyDeathSeries = chart2.series.push(new am4charts.ColumnSeries());
    dailyDeathSeries.dataFields.valueY = 'deaths';
    dailyDeathSeries.dataFields.dateX = 'date';
    dailyDeathSeries.name = 'Daily deaths';

    let cumCaseSeries = chart3.series.push(new am4charts.ColumnSeries());
    cumCaseSeries.dataFields.dateX = 'date';
    cumCaseSeries.dataFields.valueY = 'total_cases';
    cumCaseSeries.name = 'Total cases';

    let cumDeathSeries = chart4.series.push(new am4charts.ColumnSeries());
    cumDeathSeries.dataFields.dateX = 'date';
    cumDeathSeries.dataFields.valueY = 'total_deaths';
    cumDeathSeries.name = 'Total deaths';

    this.addFeatures(dailyCaseSeries, '{cases} cases');
    this.addFeatures(dailyDeathSeries, '{deaths} deaths');
    this.addFeatures(cumCaseSeries, '{total_cases} cases');
    this.addFeatures(cumDeathSeries, '{total_deaths} deaths');

    this.setupChart(chart, 'New Cases');
    this.setupChart(chart2, 'New Deaths');
    this.setupChart(chart3, 'Total Cases');
    this.setupChart(chart4, 'Total Deaths');

    this.chart = chart;
    this.chart2 = chart2;
    this.chart3 = chart3;
    this.chart4 = chart4;
  }

  updateTimeSeries() {
    this.fetchData();
  }

  private addFeatures(series, bulletText) {
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 0;
    bullet.circle.radius = 0;
    bullet.circle.fill = am4core.color("#fff");
    bullet.tooltipText = bulletText;
    
    let bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;
    //series.minBulletDistance = 15;

    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";
  }

  private setupChart(chart, titleText) {
    const title = chart.titles.create();
    title.text = titleText;
    title.fontSize = 18;
    title.fill = am4core.color('#999');
    title.marginBottom = 30;
    chart.cursor = new am4charts.XYCursor();
  }

  private addScrollbar(chart, series) {
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
  }

  private styleAxis(axis) {
    axis.renderer.labels.template.fontSize = 0;
    axis.renderer.labels.template.fill = am4core.color('#999');
  }
}
