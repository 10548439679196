<app-navbar (locationsLoaded)="setLocations($event)"></app-navbar>
<div id="content">
  <div id="current-filters">
    <h2>{{ currentLocation }}</h2> 
    <div id="date-range" *ngIf="!loading && (!params.startDate && !params.endDate)">
      {{ data.date | date: 'mediumDate' }}
    </div>
    <div *ngIf="params.startDate || params.endDate" id="date-range">{{ params.startDate | date: 'mediumDate' }} <span *ngIf="params.endDate">- {{ params.endDate | date: 'mediumDate' }} </span></div>
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <app-highlights [data]="data" *ngIf="!loading"></app-highlights>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px" *ngIf="!regionsLoading">
    <app-pie-chart title="New cases" chartId="today-cases" [data]="regionData.today" valueField="today_cases" fxFlex></app-pie-chart>
    <app-pie-chart title="New deaths" chartId="today-deaths" [data]="regionData.today" valueField="today_deaths" fxFlex></app-pie-chart>
    <app-pie-chart title="Total cases" chartId="total-cases" [data]="regionData.totals" valueField="total_cases" fxFlex></app-pie-chart>
    <app-pie-chart title="Total deaths" chartId="total-deaths" [data]="regionData.totals" valueField="total_deaths" fxFlex></app-pie-chart>
  </div>

  <div *ngIf="!loading">
    <app-time-series></app-time-series>
  </div>

  <app-table [data]="tableData" [order]="order" *ngIf="!tableLoading"></app-table>
</div>
