<div id="time-series" fxLayout="column" fxLayoutGap="20px">
  <mat-progress-bar *ngIf="timeSeriesLoading" mode="indeterminate"></mat-progress-bar>
  <div class="error" *ngIf="timeSeriesError">
    <h3><mat-icon>warning</mat-icon> Error!</h3>
    <p>There was an error loading time series data for this date range</p>
  </div>

  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <div fxFlex class="chart-container" [ngClass]="{ 'maximized': dcMaximized }">
      <button fxHide.lt-sm mat-icon-button type="button" class="expand" (click)="dcMaximized = !dcMaximized" [matTooltip]="dcMaximized ? 'Collapse' : 'Expand'">
        <mat-icon>{{ dcMaximized ? 'close' : 'fullscreen' }}</mat-icon>
      </button>
      <div fxFlex id="daily-cases-timeseries" ngClass.lt-sm="mobile" class="chart"></div>
    </div>
    <div fxFlex class="chart-container" [ngClass]="{ 'maximized': ddMaximized }">
      <button fxHide.lt-sm mat-icon-button type="button" class="expand" (click)="ddMaximized = !ddMaximized" [matTooltip]="ddMaximized ? 'Collapse' : 'Expand'">
        <mat-icon>{{ ddMaximized ? 'close' : 'fullscreen' }}</mat-icon>
      </button>
      <div fxFlex id="daily-deaths-timeseries" ngClass.lt-sm="mobile" class="chart"></div>
    </div>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <div fxFlex class="chart-container" [ngClass]="{ 'maximized': tcMaximized }">
      <button fxHide.lt-sm mat-icon-button type="button" class="expand" (click)="tcMaximized = !tcMaximized" [matTooltip]="tcMaximized ? 'Collapse' : 'Expand'">
        <mat-icon>{{ tcMaximized ? 'close' : 'fullscreen' }}</mat-icon>
      </button>
      <div fxFlex id="total-cases-timeseries" ngClass.lt-sm="mobile" class="chart"></div>
    </div>

    <div fxFlex class="chart-container" [ngClass]="{ 'maximized': tdMaximized }">
      <button fxHide.lt-sm mat-icon-button type="button" class="expand" (click)="tdMaximized = !tdMaximized" [matTooltip]="tdMaximized ? 'Collapse' : 'Expand'">
        <mat-icon>{{ tdMaximized ? 'close' : 'fullscreen' }}</mat-icon>
      </button>
      <div fxFlex id="total-deaths-timeseries" ngClass.lt-sm="mobile" class="chart"></div>
    </div>
  </div>
</div>
