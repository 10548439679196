import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { HighlightsComponent } from './highlights/highlights.component';
import { TableComponent } from './table/table.component';

import {MatDividerModule} from '@angular/material/divider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { TimeSeriesComponent } from './time-series/time-series.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FilterComponent } from './filter/filter.component';
import { FilterModalComponent } from './filter-modal/filter-modal.component';
import { FooterComponent } from './footer/footer.component';
import { OrderByPipe } from './order-by.pipe';
import { LargeNumberPipe } from './large-number.pipe';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HighlightsComponent,
    TableComponent,
    PieChartComponent,
    TimeSeriesComponent,
    FilterComponent,
    FilterModalComponent,
    FooterComponent,
    OrderByPipe,
    LargeNumberPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatDividerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatIconModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    FilterModalComponent
  ]
})
export class AppModule { }
