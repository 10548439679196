import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CovidDataService } from '../covid-data.service';
import { Params } from '../params';
import { MatDialogRef } from '@angular/material/dialog';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '../_types/location';
import { locations } from '../data/locations';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  params = new Params();
  location = 'OWID_WRL';
  locations = locations;
  @Input() dialog: MatDialogRef<FilterModalComponent>;
  @Output() locationsLoaded = new EventEmitter<any>();

  constructor(
    private covidDataService: CovidDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const locationsMap = {};
    locations.forEach(location => locationsMap[location.country_code] = location.location);
    this.locationsLoaded.emit(locationsMap);

    this.route.queryParams.subscribe(params => {
      Object.assign(this.params, {
        location: params.location || this.location
      });
      if (params.startDate) this.params.startDate = new Date(params.startDate);
      if (params.endDate) this.params.endDate = new Date(params.endDate);
    }); 
  }

  updateParameters() {
    this.covidDataService.setParams(this.params); 
    if (this.dialog) {
      this.dialog.close();
    }
  }

}
